import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const MarselPrivacyPolicy = () => {
  return (
    <Layout>
      <SEO title="Privacy Policy | Marsel" />
      <section className="privacy__wrapper container">
        <div className="privacy__hero">
          <h1 className="hero__title">Marsel Privacy Policy</h1>
          <p className="hero__descrp">
            Marsel "the App” provides upselling and cross-selling
            functionalities through its Frequently Bought Together, Also Bought
            Together, and Post Purchase Recommendation widgets to merchants who
            use Shopify to power their stores. These recommendations are based
            on the purchase history of the products for a specific store. This
            Privacy Policy describes how personal information is collected,
            used, and shared when you install or use the App in connection with
            your Shopify-supported store.
          </p>
        </div>
        <div className="policy__content">
          <h2>What Personal Information Does the App Collect?</h2>
          <p>
            When you install the App, we are automatically able to access
            certain types of information from your Shopify account:
          </p>
          <ul>
            <li>
              We store Shopify app access tokens during the app installation
              process.
            </li>
            <li>
              We’re able to access basic store details, like the store name and
              currency type by using widgets.
            </li>
            <li>
              We’re able to read orders from the past 60 days and ongoing orders
              using webhooks. We store this data to generate recommended
              products for our ‘Also Bought’ and ‘Frequently Bought Together’
              widgets.
            </li>
            <li>
              We use ongoing order data to track the revenue generated by the
              App and will display it in the App Analytics dashboard.
            </li>
            <li>
              We read product data and store it to use with our recommendations
              API.
            </li>
          </ul>
          <p>
            Additionally, it should be noted that we don’t collect personal
            information from you and/or your customers once you have installed
            the App.
          </p>
          <ul>
            <li>
              We don’t collect any personal information for customer
              registration, since we only use the basic details of the store
              from the API.
            </li>
            <li>
              We don’t collect or store any information about buyers, including
              their names, contact details, or billing information.
            </li>
            <li>
              We don’t collect or store any personal information about merchants
              or buyers, including their IP address, web browser details, time
              zone, or information about cookies installed on the device.
            </li>
            <li>
              We don’t use any tracking technology such as “pixels” or
              “cookies”.
            </li>
          </ul>
          <h2>How Do We Use Your Personal Information?</h2>
          <p>
            We use the personal information we collect from you and your
            customers in order to provide the Service and to operate the App.
            Additionally, we only use this personal information to: communicate
            with you, and optimize or improve the App.
          </p>
          <h2>Sharing Your Personal Information</h2>
          <p>
            In specific cases, our customer support information will only be
            shared with a 3rd party app that is primarily used to provide app
            support [More information <a href="https://www.helpscout.com/company/legal/privacy/" target="_blank" rel="noopener noreferrer">here</a> ]. Finally, we may also share your
            Personal Information to comply with applicable laws and regulations,
            to respond to a subpoena, search warrant or other lawful request for
            information we receive, or to otherwise protect our rights.
          </p>
          <h2>Behavioral Advertising</h2>
          <p>
            We do not use your Personal Information to provide you with targeted
            advertisements or marketing communications. For more information
            about how targeted advertising works, you can visit the Network
            Advertising Initiative’s (“NAI”) educational page at{" "}
            <a
              href="http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work"
              target="_blank"
              rel="noopener noreferrer"
            >
              http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work.
            </a> You can opt out of some of these services by visiting the Digital
            Advertising Alliance’s opt-out portal at: <a href="http://optout.aboutads.info/">
              http://optout.aboutads.info/.
            </a>
          </p>
          <h2>Your Rights</h2>
          <p>
            If you are a European resident, you have the right to access
            personal information we hold about you and to ask that your personal
            information be corrected, updated, or deleted. If you would like to
            exercise this right, please contact us through the contact
            information below.
          </p>
          <p>
            Additionally, if you are a European resident we note that we are
            processing your information in order to fulfill contracts we might
            have with you (for example if you make an order through the Site),
            or otherwise to pursue our legitimate business interests listed
            above. Additionally, please note that your information will be
            transferred outside of Europe, including to Canada and the United
            States.
          </p>
          <h2>Data Retention </h2>
          <p>
            When you place an order through the Site, we will maintain your
            Order Information for our records.
          </p>
          <ul>
            <li>
              We read and store your past order data (for a period of 60 days)
              and also ongoing order data for the recommendations API.
            </li>
            <li>
              If you uninstall the app, all your store related data will be
              deleted from our database.
            </li>
          </ul>
          <h2>Changes</h2>
          <p>
            We may update this privacy policy from time to time in order to
            reflect, for example, changes to our practices or for other
            operational, legal or regulatory reasons.
          </p>
          <h2>Contact Us</h2>
          <p>
            For more information about our privacy practices, if you have
            questions, or if you would like to make a complaint, please contact
            us by email at{" "}
            <a href="mailto:support@coderapper.com">support@coderapper.com</a>{" "}
            or by mail using the details provided below:
          </p>
          <p className="address">
            <i>
              CodeRapper, 579, 9th A Main Rd, First Stage, Defence Colony,
              Indiranagar, Bengaluru, Karnataka, India 560038
            </i>
          </p>
        </div>
      </section>
    </Layout>
  )
}

export default MarselPrivacyPolicy
